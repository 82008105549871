import { jsx as _jsx } from "react/jsx-runtime";
import { Chart, registerables } from "chart.js";
import * as React from "react";
import { Radar } from "react-chartjs-2";
import APIError from "../../components/APIError/APIError";
import NoDataMessage from "../DataError/NoDataMessage";
const EnergyChart = ({ date, essEnergyActivitiesApiStatus, essUsage, machineEnergyActivitiesApiStatus, machineUsage, refetchEssEnergyActivities, refetchMachineEnergyActivities }) => {
    if (essEnergyActivitiesApiStatus === "error") {
        return _jsx(APIError, { refreshHandler: refetchEssEnergyActivities });
    }
    if (machineEnergyActivitiesApiStatus === "error") {
        return _jsx(APIError, { refreshHandler: refetchMachineEnergyActivities });
    }
    const radarData = {
        datasets: [
            {
                data: essUsage,
                label: "Energy into ESS Units (kWh)"
            },
            {
                data: machineUsage,
                label: "Energy out of Machines (kWh)"
            }
        ],
        labels: [
            "00:00",
            "01:00",
            "02:00",
            "03:00",
            "04:00",
            "05:00",
            "06:00",
            "07:00",
            "08:00",
            "09:00",
            "10:00",
            "11:00",
            "12:00",
            "13:00",
            "14:00",
            "15:00",
            "16:00",
            "17:00",
            "18:00",
            "19:00",
            "20:00",
            "21:00",
            "22:00",
            "23:00"
        ]
    };
    const options = {
        elements: {
            line: {
                borderWidth: 2
            },
            point: {
                radius: 1
            }
        },
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                labels: {
                    boxHeight: 8,
                    boxWidth: 8,
                    font: {
                        size: 14,
                        weight: "bold"
                    },
                    pointStyle: "circle",
                    usePointStyle: true
                },
                position: "bottom",
                title: {
                    display: true
                }
            },
            tooltip: {
                enabled: true
            }
        },
        scales: {
            r: {
                angleLines: {
                    color: "grey"
                },
                grid: {
                    circular: true,
                    color: "grey",
                    lineWidth: 0.5
                },
                pointLabels: {
                    font: {
                        size: 14
                    }
                }
            }
        }
    };
    Chart.register(...registerables);
    return (_jsx("div", { className: "flex flex-col h-full", "data-test": "Energy-chart-container", children: radarData.datasets.length === 0 ? (_jsx(NoDataMessage, { date: date })) : (_jsx(Radar, { data: radarData, options: options })) }));
};
export default EnergyChart;
